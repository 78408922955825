import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取配置规则
export function getchangeRequestConfig(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/changeRequestConfig`,
    method: "get",
  });
}
// 修改配置委员会
export function changeCommittee(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/changeCommittee`,
    method: "put",
    data: params.data
  });
}
// 修改通过规则
export function changeRule(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/rule`,
    method: "put",
    data: params.data
  });
}
