<template>
  <div class="change_request">
    <p v-if="!is_edit">
      <el-button type="primary" size="small" @click="edit_click">
        <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
        {{ $t("btn.editBtn") }}</el-button
      >
    </p>
    <p v-else>
      <el-button type="info" size="small" @click="quit_edit"
        ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
        {{ $t("btn.exitEditBtn") }}</el-button
      >
      <el-button type="primary" size="small" @click="quit_edit_save"
        ><i class="iconfont icon-branch_edit" style="margin-right: 5px"></i
        >{{ $t("changeRequest.saveAndExitBtn") }}</el-button
      >
    </p>
    <div v-if="!is_edit" class="unedit" style="margin-top: 28px">
      <div>
        <span style="font-size: 14px;width: 160px;">{{ $t("changeRequest.field1") }} </span>
        <span
          v-if="!form.switch"
          style="font-size: 14px !important; color: #777 !important; min-width: 400px;"
        >
          {{ $t("changeRequest.placeholder1") }}
        </span>
        <div>
          <div
            v-for="(item, index) in form.user1"
            :key="index"
            style="font-size: 14px"
          >
            <personal-avatar
              v-if="form.switch"
              :size="40"
              :avatar="matchUserInfo(item).avatar || ''"
              :nickname="matchUserInfo(item).nickname"
              :colorid="matchUserInfo(item).accountId"
              style="margin: 0 auto"
            ></personal-avatar>
            <span v-if="form.switch">{{ matchUserInfo(item).nickname }}</span>
          </div>
        </div>
      </div>
      <div>
        <span style="font-size: 14px;width: 160px;">{{ $t("changeRequest.field2") }}</span>
        <span
          v-if="form.radio === 'AUTO'"
          style="font-size: 14px !important; color: #777 !important"
          >{{ $t("changeRequest.placeholder2") }} {{ form.num1 }}
          {{ $t("changeRequest.placeholder3") }}</span
        >
        <span v-else style="font-size: 14px"
          >{{ $t("changeRequest.placeholder2") }} {{ form.num2 }}
          {{ $t("changeRequest.placeholder4") }}
          <span v-for="(item, index) in form.user2" :key="index">
            <span style="color: rgba(48, 100, 143, 1); font-weight: bold">{{
              matchUserInfo(item).nickname
            }}</span
            >&nbsp;&nbsp;<span v-if="index < form.user2.length - 1">{{
              $t("or")
            }}</span
            >&nbsp;&nbsp;
          </span>
          {{ $t("changeRequest.placeholder5") }}
        </span>
      </div>
    </div>
    <div v-else class="isedit">
      <div>
        <span style="position: relative">
          <el-switch
            v-model="form.switch"
            class="switch_button"
            active-color="#43CF7C"
          >
          </el-switch>
          <span style="font-size: 14px;margin-left: 6px;">{{ $t("changeRequest.field1") }}</span>
        </span>
        <div>
          <el-select
            v-model="form.user1"
            size="small"
            multiple
            filterable
            :placeholder="$t('placeholder.dropdown')"
            style="width: 426px"
            :disabled="!form.switch"
          >
            <el-option
              v-for="user in allUserList"
              v-show="
                !user.deleted &&
                PUserList.some((item) => {
                  return item.accountId === user.accountId;
                })
              "
              :key="user.accountId"
              :label="user.nickname"
              :value="user.accountId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <span style="font-size: 14px;margin-top: -30px;">{{ $t("changeRequest.field2") }}</span>
        <div style="font-size: 14px">
          <p>
            <el-radio v-model="form.radio" label="AUTO"
              >{{ $t("changeRequest.auto") }}：{{
                $t("changeRequest.placeholder2")
              }}<el-input-number
                v-model="form.num1"
                :step="1"
                :precision="0"
                size="mini"
                :min="1"
                :max="100"
              ></el-input-number
              >{{ $t("changeRequest.placeholder3") }}</el-radio
            >
          </p>
          <p>
            <el-radio v-model="form.radio" label="MANUAL"
              >{{ $t("changeRequest.byHand") }}：{{
                $t("changeRequest.placeholder2")
              }}<el-input-number
                v-model="form.num2"
                :step="1"
                :precision="0"
                size="mini"
                :min="1"
                :max="100"
              ></el-input-number
              >{{ $t("changeRequest.placeholder4") }}
              <el-select
                v-model="form.user2"
                multiple
                filterable
                size="mini"
                :placeholder="$t('placeholder.dropdown')"
              >
                <el-option
                  v-for="user in user_list"
                  v-show="
                    !user.deleted &&
                    PUserList.some((item) => {
                      return item.accountId === user.accountId;
                    })
                  "
                  :key="user.accountId"
                  :label="user.nickname"
                  :value="user.accountId"
                >
                </el-option> </el-select
              >{{ $t("changeRequest.placeholder5") }}</el-radio
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import {
  getchangeRequestConfig,
  changeCommittee,
  changeRule,
} from "@/network/changeRequest/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
    PersonalAvatar,
  },
  data() {
    return {
      is_edit: false,
      form: {
        user1: [],
        user2: [],
        radio: "AUTO",
        switch: false,
        num1: 0,
        num2: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["user_list", "project_user_list"]),
    allUserList() {
      return Object.values(this.user_list);
    },
    PUserList() {
      return Object.values(this.project_user_list);
    },
  },
  mounted() {
    this.getchangeRequestConfig();
  },
  methods: {
    async getchangeRequestConfig(flag) {
      const res = await getchangeRequestConfig(this.get_pid());
      this.form.user1 = res.reviewerList;
      this.form.switch = res.switchFlag;
      this.form.radio = res.rule.mergeType;
      this.form.user2 = res.rule.confirmUsers;
      if (res.rule.mergeType === "AUTO") {
        this.form.num1 = res.rule.threshold;
      } else {
        this.form.num2 = res.rule.threshold;
      }
      if (flag) {
        this.is_edit = !this.is_edit;
      }
    },
    edit_click() {
      this.is_edit = !this.is_edit;
    },
    quit_edit() {
      this.getchangeRequestConfig(true);
    },
    async quit_edit_save() {
      if (this.form.switch && !this.form.user1.length) {
        this.$message({
          type: "warning",
          message: this.$t("changeRequest.info"),
        });
        return;
      }
      const params1 = {
        projectId: this.get_pid(),
        data: {
          reviewerList: this.form.user1,
          switchFlag: this.form.switch,
        },
      };
      const params2 = {
        projectId: this.get_pid(),
        data: {
          confirmUsers: this.form.user2,
          mergeType: this.form.radio,
          threshold:
            this.form.radio === "AUTO" ? this.form.num1 : this.form.num2,
        },
      };
      await changeCommittee(params1);
      await changeRule(params2);
      this.getchangeRequestConfig();
      this.is_edit = !this.is_edit;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-button {
  font-size: 16px;
  padding: 6px 10px;
}
.change_request {
  .switch_button {
  }
  & > p {
    margin: 0;
    text-align: right;
    margin-bottom: 5px;
  }
  & > div {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    text-align: left;
    padding-left: 20px;
    & > div {
      padding: 30px 0;
      display: flex;
      align-items: center;
      & > span:first-of-type {
        display: inline-block;
        color: rgba(128, 128, 128, 1);
        font-size: 16px;
        font-weight: 700;
        width: 195px;
        flex-shrink: 0;
      }
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      & > div {
        display: inline-block;
        vertical-align: middle;
        overflow-x: scroll;
        white-space: nowrap;
        width: 80%;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background-color: #ebeef5;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #ccc;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background: rgba(255, 255, 255, 1);
        }
        & > div {
          display: inline-block;
          vertical-align: middle;
          margin: 0 36px 0 0;
          & > span {
            display: inline-block;
            margin-top: 4px;
            min-width: 40px; /*no*/
            text-align: center;
          }
        }
      }
    }
  }
  .isedit {
    & > div {
      &:first-of-type {
        padding-top: 50px;
        & > div {
          vertical-align: baseline;
          overflow: hidden;
        }
      }
      &:last-of-type {
        & > span {
          vertical-align: top;
        }
        & > div {
          margin-top: -20px;
        }
      }
    }
  }
}
</style>
